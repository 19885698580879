@import '~antd/dist/antd.css';

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.message-content-send{
  justify-content: center;
  width: auto;
  height: auto;
  min-height: 40px;
  min-width: 80px;
  max-width: 40%;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: larger;
  text-align: center;
  align-items: center;
  margin-right: 5px;
  margin-left: auto;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.message-content-receive{
  justify-content: center;
  width: auto;
  height: auto;
  min-height: 40px;
  min-width: 200px;
  max-width: 40%;
  background-color: cornflowerblue;
  border-radius: 5px;
  color: white;
  display: flex;
  font-size: larger;
  text-align: center;
  align-items: center;
  margin-right: auto;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.message-meta-send{
  max-height: 20px;
  flex-direction: row;
  max-width: 200px;
  font-size: smaller;
  margin-left: auto;
}
.message-meta-receive{
  max-height: 20px;
  flex-direction: row;
  max-width: 200px;
  font-size: smaller;
  margin-right: auto;
}
.message{
  display: flex;
  flex-direction: column;
  min-width: 500px;
margin-left: auto;
}

.chat-header{
  font-size: larger;
  text-decoration-color: #021529;
}

.welcome-message{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-text{
  color: #3b67b0;
  position: relative;
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.welcome-user{
  color: #ff8b00;
  animation-name: fadeInBottom;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeInBottom { animation-name: fadeInBottom }

@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(100%);
  }
  to { opacity: 1 }
}

.formheader{
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.doughnut-container {
  width: 30vw;
  height: 40vh;
  background-color: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bar-container {
  width: 60vw;
  height: 40vh;
  background-color: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reimbursement-visuals {
  display: flex;
  flex-direction: row;
  padding: 50px;
}

.reimbursement-category {
  margin-left: 50px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}